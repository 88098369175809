import { createSelector, createSlice } from '@reduxjs/toolkit';
import i18n from '../i18n';

/**
 * Changes the language of the application and updates the settings if necessary.
 */
export const changeLanguage = (languageId) => async (dispatch) => {
	/*
	Change Language
	 */
	return i18n.changeLanguage(languageId).then(() => {
		dispatch(i18nSlice.actions.languageChanged(languageId));
	});
};

/**
 * The i18n slice
 */
const i18nSlice = createSlice({
	name: 'i18n',
	initialState: {
		enabledIntegration: false,
		language: i18n.options.lng,
		languages: [
			{ id: 'en-US', title: 'English', flag: 'US', show: 'EN' },
			{ id: 'pt-BR', title: 'Portuguese ', flag: 'BR', show: 'PT' },
			{ id: 'es-CO', title: 'Spanish', flag: 'MEX', show: 'LAT' },
		]
	},
	reducers: {
		setCleanI18n: (state) => {
			state.language = i18n.options.lng;
		},
		/**
		 * Updates the state with the new language.
		 */
		languageChanged: (state, action) => {
			state.language = action.payload;
		}
	}
});

export const { languageChanged, setCleanI18n } =
	i18nSlice.actions;

export default i18nSlice.reducer;
