import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  CircularProgress,
  useMediaQuery,
  IconButton,
  InputAdornment
} from '@mui/material';
import { es } from 'date-fns/locale';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

import CustomLoading from '../../components/CustomLoading';

import CustomButton from '../../components/CustomButton';
import CustomTextField from '../../components/CustomTextField';
import { getProfileById, updateProfile } from '../../store/personSlice';
import { showMessage } from '../../store/messageSlice';

const defaultValues = {
  person_name: '',
  person_lastname: '',
  person_email: '',
  person_phone: '',
  current_password: '',
  new_password: '',
  new_password_confirm: '',
  person_seller: null,
};

const schema = yup.object().shape({
  person_name: yup.string().required('Nombres es requerido'),
  person_lastname: yup.string().required('Apellidos es requerido'),
  person_email: yup
    .string()
    .email('Email no válido')
    .required('Email es requerido'),
  person_phone: yup
    .string()
    .matches(/^[0-9]+$/, 'Celular debe contener solo números')
    .min(12, 'Celular debe tener al menos 12 dígitos incluyendo el indicativo del país')
    .max(15, 'Celular debe tener un máximo de 15 dígitos incluyendo el indicativo del país')
    .required('Celular es requerido'),

  current_password: yup
    .string()
    .notRequired()
    .optional()
    .when('new_password', (new_password, field) =>
      new_password && new_password.length > 0 && new_password[0] !== ''
        ? field.required('La contraseña actual es requerida')
        : field
    ),
  new_password: yup
    .string()
    .notRequired()
    .optional()
    .when('current_password', (current_password, field) =>
      current_password && current_password.length > 0 && current_password[0] !== ''
        ? field
          .required('La nueva contraseña es requerida')
          .min(6, 'Contraseña debe tener al menos 6 caracteres')
          .matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
          .matches(/\d/, 'Contraseña debe tener al menos un número')
          .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
        : field
    ),
  new_password_confirm: yup
    .string()
    .oneOf([yup.ref('new_password'), ''], 'Las contraseñas deben coincidir')
    .when('new_password', (new_password, field) =>
      new_password && new_password.length > 0 && new_password[0] !== ''
        ? field.required('Confirmar la nueva contraseña es requerida')
        : field
    ),
}, ['current_password', 'new_password']);

function Profile() {
  const dispatch = useDispatch();
  const authUser = useSelector(({ user }) => user.user);
  const profile = useSelector(({ person }) => person.dataProfile);
  const smallScreen = useMediaQuery('(min-width:600px)');
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, watch, setValue, getValues, control, onChange, formState, handleSubmit } = methods;
  const { errors } = formState;
  const form = watch();

  const [values, setValues] = useState({
    showPasswordCurrent: false,
    showPasswordNew: false,
    showPasswordConfirm: false,
  });
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [verifyUrl, setVerifyUrl] = useState(false);

  useEffect(() => {
    function fetch() {
      try {
        new URL(`https://react-material.fusetheme.com/sign-in`);
        setVerifyUrl(true);
      } catch (err) {
        setVerifyUrl(false);
      }
    }
    fetch();
  }, [])

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('my_products')}</p>
        {/* <CustomButton
          label="Nuevo"
          typebutton={2}
          icon={<AddRoundedIcon fontSize="large" />}
          onClick={toggleDialogCreate}
        /> */}
      </div>
      <div className="w-full mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
      {verifyUrl && (
        <iframe
          id="cirugia_asistencia_iframe"
          name="cirugia_asistencia_iframe"
          title="Cirugías y Asistencias de citas"
          style={{ width: '100%', height: '100vh' }}
          // allowfullscreen 
          // frameborder="0"
          src={`https://react-material.fusetheme.com/sign-in`}>
        </iframe>
      )}
      </div>
    </div>
  );
}

export default Profile;
