import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '../../components/CustomButton';
import CustomTextField from '../../components/CustomTextField';

import {
  create,
  update,
} from '../../store/serviceGroupSlice';
import { showMessage } from '../../store/messageSlice';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  group_name: '',
};

const schema = yup.object().shape({
  group_name: yup.string().required('Nombre es requerido'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit } = methods;
  const { errors } = formState;

  const initDialog = useCallback(async () => {
    reset(item);
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    reset(defaultValues);
  };

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create(data));
      if (response) {
        await dispatch(showMessage({ message: 'Service group creada', variant: 'success' }));
      }
    } else {
      const response = await dispatch(update(data,
        item.group_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Service group actualizada', variant: 'success' }));
      }
    }
    cleanData();
    setLoading(false);
    handleClose();
    handleRefresh();

  };

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Service group
        </p>
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <Controller
              name="group_name"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="group_name"
                  label="Nombre"
                  // value={form.cli_nombre_empresa}
                  error={!!errors.group_name}
                  helperText={errors?.group_name?.message}
                  required
                />
              )}
            />
          </div>
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
