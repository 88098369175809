import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const propTypes = {
  label: PropTypes.string,
  typebutton: PropTypes.number,
  icon: PropTypes.any,
  endIcon: PropTypes.any,
  styles: PropTypes.any
};

const defaultProps = {
  label: '',
  typebutton: 1,
  icon: '',
  endIcon: '',
  styles: ''
};

/**
 * Reusable component representing a custom button with styling and configuration options.
 *
 * @component
 * @param {object} props - Properties of the component.
 * @param {string} props.label - Text displayed on the button.
 * @param {number} props.typebutton - Button type (1 - Black or 2 - Red).
 * @param {React.Element} props.icon - Icon element displayed next to the button text.
 * @param {object} props.styles - Additional styles for the button (optional).
 * @returns {JSX.Element} - Custom button element.
 */
function CustomButton(props) {
  const { label, typebutton, icon, styles, rounded, endIcon } = props;

  const ButtonStyles = {
    ...styles,
    color: 'white',
    backgroundColor: typebutton === 1 ? '#213462' : '#4575D5',
    fontSize: rounded ? 13 :15,
    textTransform: 'capitalize',
    borderRadius: rounded ? '20px':'6px',
    padding: '12px 15px',
    lineHeight:1,
    minWidth: 100,
    '&:hover': {
      backgroundColor: typebutton === 1 ? '#213462' : '#4575D5'
    },
    '& .MuiButton-startIcon': {
      marginRight: '4px'
    },
    '&:disabled ': {
      backgroundColor: typebutton === 1 ? '#213462' : '#4575D5',
      color: 'white'
    }
  };

  return (
    <Button {...props} sx={ButtonStyles} startIcon={icon} endIcon={endIcon}>
      {label}
    </Button>
  );
}

CustomButton.propTypes = propTypes;
CustomButton.defaultProps = defaultProps;

export default CustomButton;
