import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
// import clsx from "clsx";
// import currency from 'currency.js';

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';

import CustomLoading from '../../components/CustomLoading';
import CustomTableHead from '../../components/CustomTableHead';
import CustomTablePagination from '../../components/CustomTablePagination';

// import { getComparator, stableSort } from '../../utils/tablesFunctions';
import CustomButton from '../../components/CustomButton';
import CustomDialog from './CustomDialog';
import { getAll, setRowsPerPage, setPage } from '../../store/sellerSlice';

const headCells = [
  {
    id: 'seller_id',
    order: true,
    label: 'Id'
  },
  {
    id: 'seller_create_date',
    order: true,
    label: 'Fecha de creación'
  },
  {
    id: 'seller_country',
    order: true,
    label: 'País'
  },
  {
    id: 'seller_name',
    order: true,
    label: 'Nombre'
  },
  // {
  //   id: 'seller_document_type',
  //   order: true,
  //   label: 'Tipo de documento'
  // },
  // {
  //   id: 'seller_document_id',
  //   order: true,
  //   label: 'Documento'
  // },
  // {
  //   id: 'seller_address',
  //   order: true,
  //   label: 'Dirección'
  // },
  {
    id: 'seller_city_id',
    order: true,
    label: 'Ciudad'
  },
  // {
  //   id: 'seller_link_video',
  //   order: true,
  //   label: 'Link video'
  // },
  // {
  //   id: 'seller_url',
  //   order: true,
  //   label: 'Url Pagina'
  // },
  {
    id: 'seller_diagnostic',
    order: true,
    label: 'Realizó diagnóstico'
  },
  {
    id: 'seller_diagnostic_export',
    order: false,
    label: 'Diagnóstico exportador'
  },
  // {
  //   id: 'seller_hubspot_company',
  //   order: true,
  //   label: 'Hubspot company id'
  // },
  {
    id: 'action',
    order: false,
    label: 'Acción'
  },
];

function Index() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rows = useSelector(({ seller }) => seller.dataList);
  const page = useSelector(({ seller }) => seller.page);
  const rowsPerPage = useSelector(({ seller }) => seller.rowsPerPage);
  const totalRows = useSelector(({ seller }) => seller.total);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(0);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'seller_id',
  });
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [itemSelect, setItemSelect] = useState('');

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await dispatch(
        getAll(page, rowsPerPage, order.id, order.direction, {})
      );
      setLoading(false);
    };
    fetch();
  }, [dispatch, page, rowsPerPage, order.id, order.direction]);

  useEffect(() => {
    const fetch = async () => {
      if (rows && rows.length > 0) {
        setItems(rows);
      }
    };
    fetch();
  }, [rows]);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
   * Handles page change in table pagination.
   * @param {number} newPage - New selected page.
   */
  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };
  /**
   * Handles the change in the number of rows per page in the table pagination.
   * @param {Object} event - React event object.
   */
  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  const toggleDialogCreate = () => {
    setAddDialog(!addDialog);
  };

  const toggleDialogEdit = () => {
    setEditDialog(!editDialog);
  };

  const handleRefresh = async () => {
    await dispatch(
      getAll(page, rowsPerPage, order.id, order.direction, {})
    );
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('sellers')}</p>
        <CustomButton
          label="Nuevo"
          typebutton={2}
          icon={<AddRoundedIcon fontSize="large" />}
          onClick={toggleDialogCreate}
        />
      </div>
      <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        <div className="bg-red flex justify-end pb-4 px-4">
          <div className="flex">
            <CustomTablePagination
              page={page}
              count={totalRows}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
        {loading ? (
          <CustomLoading />
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items && items.length > 0 ? (
                  items.map((e) => {
                    return (
                      <TableRow
                        hover
                        key={e.seller_id}
                      >
                        <TableCell sx={{ p: '15px 3px' }}>{e.seller_id}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{format(new Date(`${e.seller_create_date}`), "yyyy-MM-dd")}{' '}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.fk_country?.country_name}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.seller_name}</TableCell>
                        {/* <TableCell sx={{ p: '15px 3px' }}>{e.seller_document_id}</TableCell> */}
                        {/* <TableCell sx={{ p: '15px 3px' }}>{e.seller_document_id}</TableCell> */}
                        {/* <TableCell sx={{ p: '15px 3px' }}>{e.seller_address}</TableCell> */}
                        <TableCell sx={{ p: '15px 3px' }}>{e.fk_city?.fk_state?.fk_country?.country_code} - {e.fk_city?.fk_state?.state_name} - {e.fk_city?.city_name}</TableCell>
                        {/* <TableCell sx={{ p: '15px 3px' }}>{e.seller_link_video}</TableCell> */}
                        {/* <TableCell sx={{ p: '15px 3px' }}>{e.seller_url}</TableCell> */}
                        <TableCell sx={{ p: '15px 3px' }}>{e.seller_diagnostic === 1 ? "Si" : "No"}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.seller_diagnostic === 1 ? `${e.diagnostics?.diagnostic_score}%` : "--"}</TableCell>
                        {/* <TableCell sx={{ p: '15px 3px' }}>{e.seller_hubspot_company}</TableCell> */}
                        <TableCell
                          align="center"
                          sx={{ p: '14px 3px' }}
                        >
                          <IconButton
                            onClick={() => {
                              setItemSelect(e);
                              setEditDialog(true)
                            }}
                          >
                            <BorderColorRoundedIcon
                              style={{ color: 'black' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 18 }}
                      colSpan={10}
                    >
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div className="flex justify-end mt-5 px-3">
          <CustomTablePagination
            page={page}
            count={totalRows}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {addDialog && (
        <CustomDialog
          handleClose={toggleDialogCreate}
          handleRefresh={handleRefresh}
          open={addDialog}
          type="create"
        />
      )}
      {editDialog && (
        <CustomDialog
          item={itemSelect}
          open={editDialog}
          handleClose={toggleDialogEdit}
          handleRefresh={handleRefresh}
          type="edit"
        />
      )}
    </div>
  );
}

export default Index;
