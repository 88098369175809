import './App.css';
import * as React from 'react';
import { Provider } from 'react-redux';

import CustomDrawer from './components/Drawer';
import CustomSnackbar from './components/CustomSnackbar';
import AppRoutes from './auth/services/appRoutes';
import store from './store';
import AuthProvider from './auth/services/AuthContext';

function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <AuthProvider>
          <CustomDrawer contentRoutes={<AppRoutes />} />
          <CustomSnackbar />
        </AuthProvider>
      </Provider>
    </React.Fragment>
  );
}

export default App;
