import { Navigate } from "react-router-dom";

import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TroubleshootRoundedIcon from '@mui/icons-material/TroubleshootRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import RoomServiceRoundedIcon from '@mui/icons-material/RoomServiceRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import LocalPlayRoundedIcon from '@mui/icons-material/LocalPlayRounded';

import Dashboard from '../pages/Dashboard';
import Sellers from '../pages/Sellers';
import DocumentTypes from '../pages/DocumentTypes';
import Cities from '../pages/Cities';
import States from '../pages/States';
import ServicesGroup from '../pages/ServicesGroup';
import Roles from '../pages/Roles';
import ServicesList from '../pages/ServicesList';
import Questions from '../pages/Questions';
import Answers from '../pages/Answers';
import Persons from '../pages/Persons';
import Diagnostics from '../pages/Diagnostics';
import Evaluations from '../pages/Evaluations';
import Profile from '../pages/Profile';
import SellerServices from '../pages/SellerServices';
import MyServices from '../pages/MyServices';
import MyProducts from '../pages/MyProducts';
import MyActivities from '../pages/MyActivities';

export const routes = [
  {
    path: '/',
    title: 'Dashboard',
    element: <Dashboard />,
    icon: <HomeRoundedIcon />,
    translate: 'dashboard',
  },
  {
    path: '/sellers',
    title: 'Sellers',
    element: <Sellers />,
    icon: <GroupsRoundedIcon />,
    translate: 'sellers',
  },
  {
    path: '/roles',
    title: 'Roles',
    element: <Roles />,
    icon: <KeyRoundedIcon />,
    translate: 'roles',
  },
  {
    path: '/document-types',
    title: 'Document Types',
    element: <DocumentTypes />,
    icon: <TextSnippetRoundedIcon />,
    translate: 'document_types',
  },
  {
    path: '/states',
    title: 'States',
    element: <States />,
    icon: <PlaceRoundedIcon />,
    translate: 'states',
  },
  {
    path: '/cities',
    title: 'Cities',
    element: <Cities />,
    icon: <NearMeRoundedIcon />,
    translate: 'cities',
  },
  {
    path: '/services-group',
    title: 'Services Group',
    element: <ServicesGroup />,
    icon: <HomeRepairServiceRoundedIcon />,
    translate: 'services_group',
  },
  {
    path: '/services',
    title: 'Services',
    element: <ServicesList />,
    icon: <BuildRoundedIcon />,
    translate: 'services',
  },
  {
    path: '/questions',
    title: 'Questions',
    element: <Questions />,
    icon: <QuestionAnswerRoundedIcon />,
    translate: 'questions',
  },
  {
    path: '/answers',
    title: 'Answers',
    element: <Answers />,
    icon: <ReceiptRoundedIcon />,
    translate: 'answers',
  },
  {
    path: '/persons',
    title: 'Persons',
    element: <Persons />,
    icon: <GroupRoundedIcon />,
    translate: 'persons',
  },
  {
    path: '/diagnostics',
    title: 'Diagnostics',
    element: <Diagnostics />,
    icon: <TroubleshootRoundedIcon />,
    translate: 'diagnostics',
  },
  {
    path: '/evaluations',
    title: 'Evaluations',
    element: <Evaluations />,
    icon: <InventoryRoundedIcon />,
    translate: 'evaluations',
  },
  {
    path: '/profile',
    title: 'Profile',
    element: <Profile />,
    icon: <ManageAccountsRoundedIcon />,
    translate: 'profile',
  },
  {
    path: '/seller-services',
    title: 'Seller Services',
    element: <SellerServices />,
    icon: <SupportAgentRoundedIcon />,
    translate: 'seller_services',
  },
  {
    path: '/my-services',
    title: 'My Services',
    element: <MyServices />,
    icon: <RoomServiceRoundedIcon />,
    translate: 'my_purchases',
  },
  {
    path: '/my-products',
    title: 'My Products',
    element: <MyProducts />,
    icon: <LocalGroceryStoreRoundedIcon />,
    translate: 'my_products',
  },
  {
    path: '/my-activities',
    title: 'My Activities',
    element: <MyActivities />,
    icon: <LocalPlayRoundedIcon />,
    translate: 'my_activities',
  },
];
