import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';

import CustomButton from '../../components/CustomButton';
import CustomLoading from '../../components/CustomLoading';
import CustomDialog from './CustomDialog';

import { getQuestionByCountry, saveAnswer, getVerifyAnswer, saveScore, setCleanDiagnostic } from '../../store/diagnosticSlice';
import { showMessage } from '../../store/messageSlice';

const useStyles = makeStyles({
  typography: {
    textAlign: "left",
    fontSize: 12,
    marginBottom: 8,
    marginTop: 16,
    fontWeight: 500,
    color: '#99A0AB'
  },
  progressLabel: {
    // position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1,
    maxHeight: "75px", // borderlinearprogress root.height
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    "& span": {
      width: "100%"
    }
  }
});

const GreenCheckbox = withStyles({
  root: {
    color: '#1FD0B0',
    '&$checked': {
      color: '#1FD0B0',
    },
  },
  checked: {},
})(Checkbox);

const addCheckAttributeToQuestions = (diagnosticDetails, questions) => {
  return questions.map(question => {
    const updatedAnswers = question.answers.map(answer => {
      const match = diagnosticDetails.some(detail =>
        detail.fk_answer.answer_question_id === answer.answer_question_id && detail.detail_answer_value === answer.answer_value
      );
      return { ...answer, check: match };
    });
    return { ...question, status: updatedAnswers.some(a => a.check), answers: updatedAnswers };
  });
};

const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useSelector(({ user }) => user.user);

  const rows = useSelector(({ diagnostic }) => diagnostic.dataList);
  const answer = useSelector(({ diagnostic }) => diagnostic.dataAnswer);

  const [itemsCheck, setItemsCheck] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkboxLoading, setCheckboxLoading] = useState({});
  const [enabledQuestions, setEnabledQuestions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [diagId, setDiagId] = useState('');
  const [diagScore, setDiagScore] = useState(0);
  const [dialogScore, setDialogScore] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);

  useEffect(() => {
    async function fetchInitialData() {
      if (!isEmpty(authUser)) {
        setLoading(true);
        await dispatch(getVerifyAnswer(authUser));
        await dispatch(getQuestionByCountry(authUser?.countryId));
        setLoading(false);
      }
    };
    fetchInitialData();

    return async () => {
      await dispatch(setCleanDiagnostic())
    }
  }, [authUser]);

  useEffect(() => {
    if (rows && rows.length > 0 && answer?.diagnostic_id) {
      setDiagId(answer?.diagnostic_id)
      setDiagScore(answer?.diagnostic_score)
      const updatedQuestions = addCheckAttributeToQuestions(answer.diagnosticDetails, rows);
      setItemsCheck(updatedQuestions);
    } else {
      setItemsCheck(rows.map((question) => ({
        ...question,
        answers: question.answers.map((answer) => ({
          ...answer,
        }))
      })));
    }
  }, [rows, answer]);

  useEffect(() => {
    if (itemsCheck.length > 0) {
      const enabledQuestionIds = itemsCheck.reduce((acc, question, index) => {
        if (question.status) {
          acc.push(index);
        }
        return acc;
      }, []);

      if (enabledQuestionIds.length > 0) {
        const nextQuestionIndex = enabledQuestionIds[enabledQuestionIds.length - 1] + 1;
        setEnabledQuestions(nextQuestionIndex >= itemsCheck.length ? [] : [nextQuestionIndex]);
      } else {
        setEnabledQuestions([0]);
      }
    }

    // Calculate progress based on the number of answered questions
    if (answer?.diagnosticDetails && itemsCheck.length > 0) {
      const totalQuestions = itemsCheck.length;
      const answeredQuestions = answer.diagnosticDetails.length;
      const progressPercentage = (answeredQuestions / totalQuestions) * 100;
      setProgress(progressPercentage);
      if (progressPercentage === 100 && diagScore === 0) {
        setDialogScore(true)
      }
    }

  }, [itemsCheck, answer?.diagnosticDetails]);

  const handleAnswer = async (question, answer) => {
    const key = `${question.question_id}-${answer.answer_id}`;
    setCheckboxLoading(prev => ({ ...prev, [key]: true }));
    const response = await dispatch(saveAnswer({
      diagnostic_seller_id: authUser.sellerId,
      diagnostic_user_id: authUser.user_id,
      detail_question_id: question.question_id,
      detail_answer_value: answer.answer_value,
      detail_answer_id: answer.answer_id,
    }));
    if (response) {
      await dispatch(getVerifyAnswer(authUser));
    }
    setCheckboxLoading(prev => ({ ...prev, [key]: false }));
  };

  const handleScore = async () => {
    setLoadingInfo(true)
    const response = await dispatch(saveScore({ diagnostic_id: diagId }));
    if (response) {
      setLoadingInfo(false)
      await dispatch(getVerifyAnswer(authUser));
      await dispatch(showMessage({ message: 'Score successfully saved', variant: 'success' }));
      navigate(`/evaluations`, { replace: true })
    }
    setLoadingInfo(false)
  };

  return (
    <div>
      <div className="w-full flex flex-col md:flex-row justify-between items-center mt-4 bg-white p-2 md:p-4 rounded-lg h-auto md:h-16">
        <p className="text-lg md:text-xl font-semibold">{t('diagnostics')}</p>
        <div className="flex flex-col md:flex-row items-center mt-2 md:mt-0">
          <div className="w-full md:w-40 mb-2 md:mb-0">
            <div className="text-sm font-bold">{t('your_progress')}</div>
          </div>
          <div className="w-full md:w-60">
            <div className={classes.progressLabel}>
              <span className="text-sm font-bold">{`${Math.round(progress)}%`}</span>
            </div>
            <LinearProgress
              value={progress}
              variant="determinate"
              style={{ height: "10px" }}
            />
          </div>
        </div>
        {progress === 100 && diagScore === 0 && (
          <div className="ml-0 md:ml-5 mt-4 md:mt-0">
            <CustomButton
              label={t('generate_result')}
              typebutton={2}
              icon={<InventoryRoundedIcon fontSize="large" />}
              onClick={() => setDialogScore(true)}
            />
          </div>
        )}
      </div>

      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 md:p-4 rounded-lg h-auto">
        <p className="text-sm md:text-md">
          {t('intro')}
        </p>
      </div>

      <div className="h-fit overflow-hidden">
        <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col overflow-y-auto scrollbar w-full max-h-96">
          {loading ? (
            <CustomLoading />
          ) : (
            <div className="flex pb-4 px-2 md:px-4">
              <div style={{ opacity: diagScore > 0 ? 0.5 : 1, pointerEvents: diagScore > 0 ? 'none' : 'auto' }}>
                {itemsCheck.length > 0 && itemsCheck.map((question, i) => (
                  <div
                    className="my-5"
                    key={question.question_id}
                    style={{ opacity: enabledQuestions.includes(i) || question.status ? 1 : 0.5 }}
                  >
                    <div className="font-bold">{question.question_description}</div>
                    <div className="flex flex-col md:flex-row">
                      {question.answers.map((answer) => (
                        <div className="flex items-center mb-2 md:mb-0" key={answer.answer_id}>
                          <Typography>{answer.answer_description}</Typography>
                          <div className="flex ml-2 md:ml-5 mr-2 md:mr-5">
                            {checkboxLoading[`${question.question_id}-${answer.answer_id}`] ? (
                              <CircularProgress size={24} />
                            ) : (
                              <FormControlLabel
                                control={
                                  <GreenCheckbox
                                    checked={answer.check}
                                    onChange={() => handleAnswer(question, answer)}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                                    icon={<RadioButtonUncheckedRoundedIcon />}
                                    checkedIcon={<CheckCircleRoundedIcon />}
                                    disabled={!enabledQuestions.includes(i) && !question.status}
                                  />
                                }
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {dialogScore && (
        <CustomDialog
          open={dialogScore}
          close={() => setDialogScore(false)}
          handleAction={handleScore}
          loadingInfo={loadingInfo}
        />
      )}
    </div>

  );
}

export default Index;
