import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

/**
 * @param order - order type asc or desc
 * @param orderBy  - item by which the information will be organized
 * @param onRequestSort  - function to select the item by which the info will be arranged
 * @returns table header with options for organizing the data
 */
function CustomTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const tableCellStyles = {
    backgroundColor: 'white',
    color: '#121926',
    fontWeight: 'bold',
    fontSize: 15,
    padding: '10px 5px',
    '& .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active': {
      color: 'black',
      '& .MuiTableSortLabel-icon': {
        color: '#121926'
      }
    },
    '& .MuiButtonBase-root.MuiTableSortLabel-root:hover': {
      color: '#121926'
    },
    '& .MuiTableCell-root': {
      lineHeight: '1rem',
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ ...tableCellStyles, width: headCell.width }}
          >
            {headCell.order ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <div dangerouslySetInnerHTML={{ __html: headCell.label }}/>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: headCell.label }} />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default CustomTableHead;
