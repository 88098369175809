import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API, { getAccessToken } from './config';
import { showMessage } from './messageSlice';

//  ------------- My services -------------
export const getServicesById = (sellerId, page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/seller/${sellerId}`;
        const result = await axios.get(URL + queryString, await getAccessToken());
        await dispatch(setDataById(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getServicesById *** ${error}`);
    }
};

//  ------------- Services -------------
export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}`;
        const result = await axios.get(URL + queryString, await getAccessToken());
        await dispatch(setDataList(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getAll *** ${error}`);
    }
};

export const create = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}`;
        await axios.post(URL, data, await getAccessToken());
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> create *** ${error}`);
    }
};

export const update = (data, id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/${id}`;
        await axios.put(URL, data, await getAccessToken());
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> update *** ${error}`);
    }
};

const sellerServicesSlice = createSlice({
    name: 'sellerServ',
    initialState: {
        enabledIntegration: false,
        dataList: [],
        dataById: [],
        page: 0,
        rowsPerPage: 10,
        total: 0,
        totalServices: 0,
        totalSubscription: 0,
        totalPayment: 0,
    },
    reducers: {
        setCleanSellerServ: (state) => {
            state.dataById = {};
        },
        setDataList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setDataById: (state, action) => {
            state.dataById = action.payload.rows;
            state.total = action.payload.count;
            state.totalService = action.payload.service;
            state.totalSubscription = action.payload.subscription;
            state.totalPayment = action.payload.totalPayment;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
    }
});

export const { setCleanSellerServ, setDataList, setRowsPerPage, setPage, setTotalRows, setDataById } =
    sellerServicesSlice.actions;

export default sellerServicesSlice.reducer;
