import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API, { getAccessToken } from './config';
import { showMessage } from './messageSlice';

//  ------------- My services -------------
export const getActivitiesServiceById = (sellerId, service) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.activities_service}/seller/${sellerId}?service_id=${service}`;
        const result = await axios.get(URL);
        await dispatch(setDataById(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getActivitiesServiceById *** ${error}`);
    }
};

const activityServicesSlice = createSlice({
    name: 'activityServ',
    initialState: {
        enabledIntegration: false,
        dataById: {},
    },
    reducers: {
        setCleanActivityServ: (state) => {
            state.dataById = {};
        },
        setDataById: (state, action) => {
            state.dataById = action.payload;
        },
    }
});

export const { setCleanActivityServ, setDataById } =
    activityServicesSlice.actions;

export default activityServicesSlice.reducer;
