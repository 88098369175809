import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import clsx from "clsx";
import { format, parseISO } from 'date-fns'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import CustomTableHead from '../../components/CustomTableHead';

import { showMessage } from '../../store/messageSlice';
import { getActivitiesServiceById, setCleanActivityServ } from '../../store/activityServicesSlice';

const useStyles = makeStyles({
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthMd": {
      maxWidth: 'auto',
      width: '100%',
      // backgroundColor: 'white',
    },
  },
});

const headCells = [
  {
    id: 'ass_id',
    order: false,
    label: 'Proveedor'
  },
  {
    id: 'ass_status',
    order: false,
    label: 'Estado'
  },
  {
    id: 'ass_create_date',
    order: false,
    label: 'Fecha creación'
  },
  {
    id: 'ass_final_file',
    order: false,
    label: 'Archivo final',
  },
];

const headCellsTwo = [
  {
    id: 'assd_id',
    order: false,
    label: 'Estado'
  },
  {
    id: 'ass_status',
    order: false,
    label: 'Actividad'
  },
  {
    id: 'assd_start_date',
    order: false,
    label: 'Fecha inicio',
  },
  {
    id: 'assd_planned_date',
    order: false,
    label: 'Fecha planeada',
  },
  {
    id: 'assd_end_date',
    order: false,
    label: 'Fecha final',
  },
];

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const authUser = useSelector(({ user }) => user.user);
  const activity = useSelector(({ activityServ }) => activityServ.dataById);
  const [items, setItems] = useState({});
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'ass_id',
  });

  useEffect(() => {
    const fetch = async () => {
      if (!isEmpty(activity)) {
        setItems(activity);
      }
    };
    fetch();
  }, [activity]);

  useEffect(() => {
    async function init() {
      await dispatch(getActivitiesServiceById(authUser?.sellerId, item.sese_service_id));
    }
    init();

    return async () => {
      await dispatch(setCleanActivityServ())
    }
  }, []);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // style={{
      //   boxShadow: '0px 2px 16px 4px rgba(38, 41, 64, 0.1)',
      //   zIndex: 99,
      // }}
      className={classes.dialog}
      minWidth="lg"
      maxWidth="lg"
    >
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          Detalle service
        </p>
        <IconButton key="close" aria-label="Close" onClick={() => handleClose()} style={{ position: 'absolute', right: 16 }}>
          <CloseRoundedIcon style={{ color: '#213462' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent /* sx={{ width: '600px' }} */>
        {/* ------------ */}
        <div className="mt-4">
          <div className='flex items-center pt-4'>
            <BusinessCenterRoundedIcon style={{ color: '#213462' }} />
            <p className='ml-2 font-bold'>
              Información
            </p>
          </div>
          <div className="mb-0">
            <div className="table-responsive mb-10">
              <Table className="simple mt-4">
                <CustomTableHead
                  order={order.direction}
                  orderBy={order.id}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />
                <TableBody>
                  {!isEmpty(items) ? (
                    <TableRow>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.fk_supplier?.supplier_company}</TableCell>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.ass_status}</TableCell>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{format(parseISO(`${items.ass_create_date}`), "dd-MM-yyyy")}{' '}</TableCell>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.ass_final_file ? items.ass_final_file : 'No hay archivo'}</TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ padding: 16, fontSize: 12 }}
                        colSpan={4}
                      >
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        {/* ------------ */}
        <div className="mt-4">
          <div className='flex items-center pt-4'>
            <LibraryBooksRoundedIcon style={{ color: '#213462' }} />
            <p className='ml-2 font-bold'>
              Actividades
            </p>
          </div>
          <TableContainer>
            <Table size="small" className="simple mt-4">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCellsTwo}
              />
              <TableBody>
                {!isEmpty(items) && items.activities_service_seller_detail.length > 0 ?
                  items.activities_service_seller_detail.map((row) => (
                    <TableRow
                      key={row.assd_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ p: '15px 10px 15px 3px' }}>
                        <p
                          className={clsx(
                            'inline-flex justify-center items-center px-14 py-1 rounded-full tracking-wide uppercase whitespace-nowrap',
                            row.assd_status === 'Pendiente' &&
                            'bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50',
                            row.assd_status === 'En progreso' &&
                            'bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-red-50',
                            row.assd_status === 'Terminado' &&
                            'bg-green-50 text-green-800 dark:bg-green-600 dark:text-green-50'
                          )}
                          style={{ fontSize: 10, maxWidth: 100 }}
                        >
                          {row.assd_status}
                        </p>
                      </TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.fk_activities_service?.acse_name}</TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.assd_start_date}</TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.assd_planned_date}</TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.assd_end_date}</TableCell>
                    </TableRow>
                  )) :
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 12 }}
                      colSpan={5}
                    >
                      No data found
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ------------ */}
        <div className="flex justify-end my-4 items-center">
          <p
            className="mx-4 underline text-base text-gray-600 cursor-pointer"
            onClick={() => {
              handleClose();
              handleRefresh()
            }}
          >
            Cerrar
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
