
import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from "clsx";

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Grid, Container, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

import CustomTextField from '../../components/CustomTextField';
import CustomButton from '../../components/CustomButton';
import { useAuth } from '../../auth/services/AuthContext';

const useStyles = makeStyles({
  typography: {
    textAlign: "left",
    fontSize: 12,
    marginBottom: 8,
    marginTop: 16,
    fontWeight: 500,
    color: '#99A0AB'
  },
  customText: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      color: 'red',
      marginTop: 4,
      fontWeight: 500,
      textAlign: 'right',
    },
  },
  show: {
    '& .MuiInputBase-root': {
      background: '#fff'
    }
  }
});

const defaultValues = {
  email: '',
  password: '',
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email no válido')
    .required('Email es requerido'),
  password: yup
    .string()
    // .min(6, 'Contraseña debe tener al menos 6 caracteres')
    // .matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
    // .matches(/\d/, 'Contraseña debe tener al menos un número')
    // .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
    .required('Contraseña es requerida')
});

function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { /*setToken, setAuthUser, token,*/ signIn, getModules } = useAuth();

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    new_password: "",
    password_confirmation: "",
    showPasswordCurrent: false,
    showPasswordNew: false,
    showPasswordConfirm: false,
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { control, formState, handleSubmit } = methods;
  const { errors } = formState;

  // useEffect(() => {
  //   const fetch = async () => {
  //     if (token) {
  //       navigate("/", { replace: true });
  //     }
  //   };
  //   fetch();
  // }, [token]);

  const handleAction = async (data) => {
    setLoading(true);
    // const result = await dispatch(login(data));
    const result = await signIn(data);
    if (result) {
      const modRol = getModules();
      const defaultRoute = modRol && modRol.find(t => t.moro_default)?.fk_module.module_link || "/";
      // await setToken(result.auth_info.access_token);
      // await setAuthUser({ ...result.auth_info.user, role: result.auth_info.user.fk_role.role_description });
      // await dispatch(setUser({ ...result.auth_info.user, role: result.auth_info.user.fk_role.role_description }));
      navigate(defaultRoute, { replace: true });
      setLoading(false);
    }
    setLoading(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (params) => () => {
    setValues({
      ...values,
      [params]: !values[params],
    })
  }

  return (
    <Container sx={{ mt: 2, mb: 4 }}>
      <Grid
        container
        justifyContent='center'
      >
        <Grid
          item
          xs={11} sm={7} md={3}
          style={{ marginTop: 0 }}
        >
          <div className="flex items-center justify-center mt-10">
            <img src='/bosanet-new-white.png' width='300' height='80' alt='bosanet-new-white.png' />
          </div>
          <FormProvider {...methods}>
            <div className="mt-10 flex flex-col">
              <p className='text-[#fff]'>Email</p>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    className={classes.show}
                    styles={{ mt: 0, mb: 2, width: '100%' }}
                    id="email"
                    label=""
                    // value={form.cli_nombre_empresa}
                    error={!!errors.email}
                    helperText={<div className='text-[#fff]'>{errors?.email?.message}</div>}
                    required
                  />
                )}
              />
              <p className='text-[#fff]'>Password</p>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    className={classes.show}
                    type={values.showPasswordCurrent ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword(
                              "showPasswordCurrent"
                            )}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPasswordCurrent ? (
                              <VisibilityOffRoundedIcon />
                            ) : (
                              <VisibilityRoundedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    label=""
                    error={!!errors.password}
                    helperText={<div className='text-[#fff]'>{errors?.password?.message}</div>}
                    required
                  />
                )}
              />
            </div>
            <div className="flex justify-between my-10 items-center">
              {/* <div className='text-[#fff]'>Ingresa tus datos de registro para empezar a desarrollar tu diagnóstico de exportación.</div> */}
              <div className='w-full'>
                {loading ? (
                  <CircularProgress
                    style={{ color: '#FFf', marginRight: 4 }}
                    size={24}
                  />
                ) : (
                  <CustomButton
                    label="Ingresar"
                    onClick={handleSubmit(handleAction)}
                    typebutton={2}
                    fullWidth
                  />
                )}
              </div>

            </div>
            {/* <div className="flex justify-center mb-10 items-center">
              <RouterLink style={{ color: '#000' }} className={clsx(classes.customFontBold, "underline cursor-pointer font-semibold")} to="/forgot-password">
                Olvidé mi contraseña
              </RouterLink>
            </div> */}
            {/* <div className="flex justify-center items-center">
              <div className='mr-5'>
                <RouterLink style={{ color: '#000' }} className={clsx(classes.customFontBold, "underline cursor-pointer font-semibold")} to="/help">
                  Ayuda
                </RouterLink>
              </div>
              <div className='ml-5'>
                <RouterLink style={{ color: '#000' }} className={clsx(classes.customFontBold, "underline cursor-pointer font-semibold")} to="/terms-conditions">
                  Términos y condiciones
                </RouterLink>
              </div>
            </div> */}
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Index;