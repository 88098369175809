import React, { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Box,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';

import CustomButton from '../../components/CustomButton';
import CustomLoading from '../../components/CustomLoading';
import CustomAccordion from './CustomAccordion';

import { getVerifyAnswer, getScore, savePDF } from '../../store/diagnosticSlice';
import { showMessage } from '../../store/messageSlice';

const useStyles = makeStyles({

});

function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser = useSelector(({ user }) => user.user);

  const answer = useSelector(({ diagnostic }) => diagnostic.dataAnswer);
  const score = useSelector(({ diagnostic }) => diagnostic.dataScore);

  const [itemsCheck, setItemsCheck] = useState({});
  const [itemScore, setItemScore] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);

  useEffect(() => {
    async function fetchInitialData() {
      if (!isEmpty(authUser)) {
        setLoading(true);
        await dispatch(getVerifyAnswer(authUser));
        setLoading(false);
      }
    }
    fetchInitialData();
    return () => {
      setItemsCheck({})
    }
  }, [authUser]);

  useEffect(() => {
    async function fetch() {
      if (answer?.diagnostic_id) {
        setItemsCheck(answer);
        await dispatch(getScore(answer?.diagnostic_id));
      }
    }

    fetch();
  }, [answer]);

  useEffect(() => {
    if (score?.score_id) {
      setItemScore(score);
    }
  }, [score]);

  const handlePDF = async () => {
    setLoadingPDF(true)
    const response = await dispatch(savePDF({
      user_id: authUser.user_id,
      seller_id: authUser.sellerId
    }));
    if (response) {
      setLoadingPDF(false)
      const linkSource = `data:application/pdf;base64,${response.blob}`;
      const downloadLink = document.createElement('a');
      const fileName = `exportCheck-${Math.round(new Date().getTime() / 1000)}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      await dispatch(showMessage({ message: 'Generate PDF successfully', variant: 'success' }));
    }
    setLoadingPDF(false)
  };

  return (
    <div>
      <div className="w-full flex flex-col md:flex-row justify-between items-center mt-4 bg-white p-2 md:p-4 rounded-lg h-auto md:h-16">
        <p className="text-lg md:text-xl font-semibold">{t('export_diagnostic')}</p>
        {loadingPDF ? (
          <CircularProgress
            style={{ color: '#4575D5', marginRight: 4 }}
            size={24}
          />
        ) : (
          !isEmpty(itemScore) && answer.diagnostic_score > 0 && (
            <CustomButton
              label="Generate PDF"
              typebutton={2}
              icon={<PictureAsPdfRoundedIcon fontSize="large" />}
              onClick={handlePDF}
            />
          )
        )}
      </div>
      {!isEmpty(itemScore) && answer.diagnostic_score > 0 && (
        <div className="flex flex-col md:flex-row">
          <div className="flex justify-center items-center mt-5 mb-2 md:mb-5 mr-0 md:mr-5 p-4 md:p-8 bg-white rounded-lg h-auto w-full md:w-1/5"
            style={{
              boxShadow: '0px 4px 10px #ccc',
            }}
          >
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress size={100} variant="determinate" value={answer.diagnostic_score} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className="text-2xl md:text-3xl font-bold">
                  {`${answer.diagnostic_score}%`}
                </div>
              </Box>
            </Box>
          </div>

          <div className="flex-col items-center my-5 p-4 md:p-8 bg-white rounded-lg h-auto w-full md:w-4/5"
            style={{
              boxShadow: '0px 4px 10px #ccc',
            }}
          >
            <p className="text-justify text-sm md:text-base" style={{ fontSize: '1.1rem' }}>
              <div className="content-preview" dangerouslySetInnerHTML={{ __html: itemScore.score_description_translate }} />
            </p>
          </div>
        </div>
      )}
      <div className="mt-4 bg-white px-2 py-6 rounded-lg flex flex-col">
        {loading ? (
          <CustomLoading />
        ) : (
          <div className="flex pb-4 px-2 md:px-4">
            <div className='w-full'>
              {itemsCheck && answer.diagnostic_score > 0 && itemsCheck.diagnosticDetails && itemsCheck.diagnosticDetails.length > 0 ? (
                itemsCheck.diagnosticDetails.map((row, index) => (
                  // <Accordion key={i}>
                  //   <AccordionSummary
                  //     expandIcon={<ExpandMoreIcon />}
                  //     aria-controls={`panel${i}-content`}
                  //     id={`panel${i}-header`}
                  //   >
                  //     <p className="font-bold">{row.fk_answer.fk_question.question_description}</p>
                  //     <CheckCircleRoundedIcon
                  //       style={{
                  //         color: row.fk_answer.answer_value === 1 ? 'red' :
                  //           row.fk_answer.answer_value === 3 ? '#6c757d' :
                  //             '#00c851',
                  //         marginLeft: 10
                  //       }}
                  //     />&nbsp;
                  //     ({row.fk_answer.answer_description})
                  //   </AccordionSummary>
                  //   <AccordionDetails>
                  //     <div className="text-sm md:text-base content-preview" dangerouslySetInnerHTML={{ __html: row.fk_answer.answer_complement }} />
                  //   </AccordionDetails>
                  // </Accordion>
                  <CustomAccordion
                    index={index}
                    question={row.fk_answer.fk_question.question_description}
                    answerDescription={row.fk_answer.answer_description}
                    answerValue={row.fk_answer.answer_value}
                    answerComplement={row.fk_answer.answer_complement}
                  />
                ))
              ) : (
                <Typography>{t('there_isnt_evaluation')}</Typography>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
